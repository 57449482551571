import { useContext } from "react"
import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import DataContext from "../../Component/Elements/context"
import { ApiService } from "../../Component/Elements/Services/apiservices"
import React, { useState, useCallback, useRef, useEffect } from "react"

const AboutUs = () => {
    const contextValues = useContext(DataContext)
    const [pagesData, setPagesData] = useState({});
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            getpagesData()
        }
        didMountRef.current = false;

    }, []);
    const getpagesData = () => {
        ApiService.fetchData(`pages-data/${'about-us'}`).then((res) => {
            if (res.status === "success") {
                setPagesData(res.data)
            }
        })
    }


    return (<>
        <div id="main-wrapper" class="main-wrapper">
            <Header></Header>

            <div class="edu-breadcrumb-area breadcrumb-style-2 bg-image bg-image--19">
                <div class="container">
                    <div class="breadcrumb-inner">
                        <div class="page-title">
                            <h1 class="title">Innovating Diagnostics for a Self-Reliant India</h1>
                        </div>
                        <ul class="edu-breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="separator"><i class="icon-angle-right"></i></li>
                            <li class="breadcrumb-item"><a href="#">Pages</a></li>
                            <li class="separator"><i class="icon-angle-right"></i></li>
                            <li class="breadcrumb-item active" aria-current="page">About Us</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="section-gap-large edu-about-area about-style-7">
                <div class="container">
                    <div class="row g-5 align-items-center">
                        <div class="col-lg-6">
                            <div class="about-content">
                                <div class="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <span class="pre-title">About Us</span>
                                    <h2 class="title">We Serve Innovative and Quality <span class="color-secondary">Product</span> For You.</h2>
                                    <span class="shape-line"><i class="icon-19"></i></span>
                                    {/* <div dangerouslySetInnerHTML={{ __html: pagesData?.page_content }}></div> */}
                                    <p>GENOVIK BIOTECH PRIVATE LIMITED is a PRIVATE LIMITED firm incorporated
                                        on 02 June 2021. It is registered at Registrar of Companies, Jaipur. Based in India
                                        and led for AatmaNirbhar Bharat, A vision of the Hon’ble Prime Minister of India for
                                        making our country a self-reliant. GENOVIK BIOTECH PRIVATE LIMITED is
                                        engaged in licensing and developing innovative diagnostic products and services for
                                        disease prevention, diagnosis and personalized medicine, and strives to offer in
                                        India.</p>
                                    
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-image-gallery">
                                <img class="main-img-1" src="/images/about/about-11.webp" alt="About Image" />
                                <ul class="shape-group">
                                    <li class="shape-1 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="2" src="/images/about/shape-38.png" alt="Shape" />
                                    </li>
                                    <li class="shape-2 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="-2" src="/images/about/shape-37.png" alt="Shape" />
                                    </li>
                                    <li class="shape-3 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="-1.8" src="/images/about/shape-04.png" alt="Shape" />
                                    </li>
                                    <li class="shape-4 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img src="/images/counterup/shape-02.png" alt="Shape" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="shape-group">
                    <li class="shape-1" data-sal-delay="500" data-sal="fade" data-sal-duration="200"></li>
                </ul>
            </div>
            <div class="section-gap-large edu-about-area about-style-7">
                <div class="container">
                    <div class="row g-5 align-items-center">
                        <div class="col-lg-6">
                            <div class="about-image-gallery">
                                <img class="main-img-1" src="/images/about/about-11.webp" alt="About Image" />
                                <ul class="shape-group">
                                    <li class="shape-1 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="2" src="/images/about/shape-38.png" alt="Shape" />
                                    </li>
                                    <li class="shape-2 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="-2" src="/images/about/shape-37.png" alt="Shape" />
                                    </li>
                                    <li class="shape-3 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="-1.8" src="/images/about/shape-04.png" alt="Shape" />
                                    </li>
                                    <li class="shape-4 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img src="/images/counterup/shape-02.png" alt="Shape" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-content">
                                <div class="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">

                                    <h2 class="title">We Serve Innovative and Quality <span class="color-secondary">Product</span> For You.</h2>
                                    <span class="shape-line"><i class="icon-19"></i></span>
                                    <p> GENOVIK BIOTECH PRIVATE LIMITED vision is to grow into a multi-product,
                                        organization addressing various public health challenges facing humanity. GENOVIK
                                        BIOTECH PRIVATE LIMITED is focused on international collaboration and inlicensing to achieve its goal of becoming the leading provider of diagnostic
                                        kits/services in India with the most comprehensive tests covering disease
                                        predisposition, screening, diagnosis, classification, prognosis and therapeutic
                                        monitoring. It is the best partner to help innovative companies and leading research
                                        institutions to succeed in the Indian market. It seeks opportunities to partner with
                                        diagnostic and pharmaceutical companies and universities on diagnostic
                                        technologies and products at all stages and in all disease areas.</p>
                                    {/* <div dangerouslySetInnerHTML={{ __html: pagesData?.page_content }}></div> */}
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <ul class="shape-group">
                    <li class="shape-1" data-sal-delay="500" data-sal="fade" data-sal-duration="200"></li>
                </ul>
            </div>
            <div class="section-gap-large edu-about-area about-style-7">
                <div class="container">
                    <div class="row g-5 align-items-center">
                        <div class="col-lg-6">
                            <div class="about-content">
                                <div class="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">

                                    <h2 class="title">We Serve Innovative and Quality <span class="color-secondary">Product</span> For You.</h2>
                                    <span class="shape-line"><i class="icon-19"></i></span>
                                    <p>In the light of aging population, enhanced health awareness, and technology
                                        advancement, in vitro diagnostics (IVD) has begun to take the center-stage at the
                                        forefront of medical development. It is playing an increasingly important role in a
                                        number of fields, including disease prevention, diagnosis, monitoring, and treatment
                                        and has already become an inseparable part of modern health management. At
                                        present, about two-thirds of medical decision relies on diagnostic information.
                                        However, spend on diagnosis only accounts for about 1% of total medical expense.
                                        Thus, further improvements on diagnostic technologies and increase on diagnosis
                                        spending will definitely provide a more futile ground for scientific evidence-based
                                        decisions down the line.</p>
                                    {/* <div dangerouslySetInnerHTML={{ __html: pagesData?.page_content }}></div> */}
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-image-gallery">
                                <img class="main-img-1" src="/images/about/about-11.webp" alt="About Image" />
                                <ul class="shape-group">
                                    <li class="shape-1 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="2" src="/images/about/shape-38.png" alt="Shape" />
                                    </li>
                                    <li class="shape-2 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="-2" src="/images/about/shape-37.png" alt="Shape" />
                                    </li>
                                    <li class="shape-3 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="-1.8" src="/images/about/shape-04.png" alt="Shape" />
                                    </li>
                                    <li class="shape-4 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img src="/images/counterup/shape-02.png" alt="Shape" />
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </div>
                </div>
                <ul class="shape-group">
                    <li class="shape-1" data-sal-delay="500" data-sal="fade" data-sal-duration="200"></li>
                </ul>
            </div>
            <div class="section-gap-large edu-about-area about-style-7">
                <div class="container">
                    <div class="row g-5 align-items-center">
                        <div class="col-lg-6">
                            <div class="about-image-gallery">
                                <img class="main-img-1" src="/images/about/about-11.webp" alt="About Image" />
                                <ul class="shape-group">
                                    <li class="shape-1 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="2" src="/images/about/shape-38.png" alt="Shape" />
                                    </li>
                                    <li class="shape-2 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="-2" src="/images/about/shape-37.png" alt="Shape" />
                                    </li>
                                    <li class="shape-3 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img data-depth="-1.8" src="/images/about/shape-04.png" alt="Shape" />
                                    </li>
                                    <li class="shape-4 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                        <img src="/images/counterup/shape-02.png" alt="Shape" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-content">
                                <div class="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">

                                    <h2 class="title">We Serve Innovative and Quality <span class="color-secondary">Product</span> For You.</h2>
                                    <span class="shape-line"><i class="icon-19"></i></span>
                                    <p>Developed countries account for the majority of the global market. Their markets are
                                        relatively mature. Specifically, North America is currently the largest, accounting for
                                        approximately 40.15%, followed by western European at about 29.82%, and the
                                        Japanese market at approximately 10.45%. On the other side of the coin, developing
                                        countries, such as India, are lagging behind the leading rank; their spend on in vitro
                                        diagnostics per capita is still relatively low. However, thanks to the booming
                                        economy, they are emerging at a rapid speed.
                                    </p>
                                    <p>Based on the methods and applications, in vitro diagnostics can be categorized into a
                                        number of fields, including immune diagnosis, biochemical diagnosis, molecular
                                        diagnosis, bedside diagnosis, blood detection, and microbial detection. At present,
                                        immune and biochemical diagnosis are the most popular, accounting for the largest
                                        market globally, whereas molecular diagnosis is the rising star, representing the
                                        biggest market potential in the coming years.</p>
                                    {/* <div dangerouslySetInnerHTML={{ __html: pagesData?.page_content }}></div> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <ul class="shape-group">
                    <li class="shape-1" data-sal-delay="500" data-sal="fade" data-sal-duration="200"></li>
                </ul>
            </div>
            <div class="edu-brand-area brand-area-4 gap-lg-bottom-equal">
                <div class="container">
                    <div class="brand-grid-wrap brand-style-2">
                        <div class="brand-grid">
                            <img src="/images/brand/brand-01.png" alt="Brand Logo" />
                        </div>
                        <div class="brand-grid">
                            <img src="/images/brand/brand-02.png" alt="Brand Logo" />
                        </div>
                        <div class="brand-grid">
                            <img src="/images/brand/brand-03.png" alt="Brand Logo" />
                        </div>
                        <div class="brand-grid">
                            <img src="/images/brand/brand-04.png" alt="Brand Logo" />
                        </div>
                        <div class="brand-grid">
                            <img src="/images/brand/brand-05.png" alt="Brand Logo" />
                        </div>
                        <div class="brand-grid">
                            <img src="/images/brand/brand-06.png" alt="Brand Logo" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="counterup-area-8 gap-lg-bottom-equal">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-3 col-sm-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-counterup counterup-style-4">
                                <div class="icon primary-color">
                                    <i class="icon-48"></i>
                                </div>
                                <h2 class="counter-item count-number"><span class="odometer" data-odometer-final="29.3">.</span><span>K</span></h2>
                                <h6 class="title">Satisfaction Rate</h6>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-counterup counterup-style-4">
                                <div class="icon secondary-color">
                                    <i class="icon-47"></i>
                                </div>
                                <h2 class="counter-item count-number"><span class="odometer" data-odometer-final="32.4">.</span><span>K</span></h2>
                                <h6 class="title">Satisfaction Rate</h6>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-counterup counterup-style-4">
                                <div class="icon extra08-color">
                                    <i class="icon-49"></i>
                                </div>
                                <h2 class="counter-item count-number"><span class="odometer" data-odometer-final="100">.</span><span>%</span></h2>
                                <h6 class="title">Satisfaction Rate</h6>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-counterup counterup-style-4">
                                <div class="icon extra05-color">
                                    <i class="icon-50"></i>
                                </div>
                                <h2 class="counter-item count-number"><span class="odometer" data-odometer-final="354">.</span><span>+</span></h2>
                                <h6 class="title">Satisfaction Rate</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="shape-group">
                    <li class="shape-1" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                        <img src="/images/others/map-shape-3.png" alt="Shape" />
                    </li>
                </ul>
            </div>
            <section class="why-choose-area-2 section-gap-large">
                <div class="container edublink-animated-shape">
                    <div class="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <span class="pre-title">Why choose GENOVIK</span>
                        <h2 class="title">
                            The Best <span class="color-secondary">Beneficial</span> Side <br />
                            of GENOVIK
                        </h2>
                        <span class="shape-line"><i class="icon-19"></i></span>
                    </div>
                    <div class="row g-5">
                        <div class="col-lg-4" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <div class="why-choose-box features-box color-primary-style">
                                <div class="icon">
                                    <i class="icon-45"></i>
                                </div>
                                <div class="content">
                                    <h4 class="title">High Quality Products</h4>
                                    <p>Lorem ipsum dolor sit amet conset ur elit sed eiusmod ex tempor inc labore dolore magna.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <div class="why-choose-box features-box color-secondary-style">
                                <div class="icon">
                                    <i class="icon-46"></i>
                                </div>
                                <div class="content">
                                    <h4 class="title">Life Time Access</h4>
                                    <p>Lorem ipsum dolor sit amet conset ur elit sed eiusmod ex tempor inc labore dolore magna.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <div class="why-choose-box features-box color-extra08-style">
                                <div class="icon">
                                    <i class="icon-47"></i>
                                </div>
                                <div class="content">
                                    <h4 class="title">Experts</h4>
                                    <p>Lorem ipsum dolor sit amet conset ur elit sed eiusmod ex tempor inc labore dolore magna.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="shape-group">
                        <li class="shape-5" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                            <span></span>
                        </li>
                    </ul>
                </div>
                <ul class="shape-group">
                    <li class="shape-1 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                        <span data-depth="1"></span>
                    </li>
                    <li class="shape-2 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                        <img data-depth="-2" src="/images/about/shape-13.png" alt="shape" />
                    </li>
                    <li class="shape-3 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                        <span data-depth="-1"></span>
                    </li>
                    <li class="shape-4 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                        <img data-depth="2" src="/images/about/shape-40.png" alt="shape" />
                    </li>
                </ul>
            </section>
            <Footer></Footer>
        </div>


    </>)
}


export default AboutUs