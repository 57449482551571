import Footer from "../../Component/Footer"
import Header from "../../Component/Header"

const BlogList = () => {

    return (<>

        <div id="main-wrapper" class="main-wrapper">
            <Header></Header>
            {/* <div class="edu-breadcrumb-area">
                <div class="container">
                    <div class="breadcrumb-inner">
                        <div class="page-title">
                            <h1 class="title">Blog List</h1>
                        </div>
                        <ul class="edu-breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="separator"><i class="icon-angle-right"></i></li>
                            <li class="breadcrumb-item"><a href="#">Pages</a></li>
                            <li class="separator"><i class="icon-angle-right"></i></li>
                            <li class="breadcrumb-item active" aria-current="page">Blog 3</li>
                        </ul>
                    </div>
                </div>
                <ul class="shape-group">
                    <li class="shape-1">
                        <span></span>
                    </li>
                    <li class="shape-2 scene"><img data-depth="2" src="/images/about/shape-13.png" alt="shape" /></li>
                    <li class="shape-3 scene"><img data-depth="-2" src="/images/about/shape-15.png" alt="shape" /></li>
                    <li class="shape-4">
                        <span></span>
                    </li>
                    <li class="shape-5 scene"><img data-depth="2" src="/images/about/shape-07.png" alt="shape" /></li>
                </ul>
            </div>
            <section class="section-gap-equal">
                <div class="container">
                    <div class="row row--30">
                        <div class="col-lg-8">
                            <div class="edu-blog blog-style-list" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-25.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content">
                                        <h5 class="title"><a href="blog-details.html">4 Learning Management System Design Tips...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adip isicing sed eiusmod.</p>
                                        <div class="read-more-btn">
                                            <a class="edu-btn btn-border btn-medium" href="blog-details.html">Learn More <i class="icon-4"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="edu-blog blog-style-list" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-26.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content">
                                        <h5 class="title"><a href="blog-details.html">How to Developers Taking the Guess Work...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adip isicing sed eiusmod.</p>
                                        <div class="read-more-btn">
                                            <a class="edu-btn btn-border btn-medium" href="blog-details.html">Learn More <i class="icon-4"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="edu-blog blog-style-list" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-27.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content">
                                        <h5 class="title"><a href="blog-details.html">Ten Benefits Of Rentals That May Change Your...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adip isicing sed eiusmod.</p>
                                        <div class="read-more-btn">
                                            <a class="edu-btn btn-border btn-medium" href="blog-details.html">Learn More <i class="icon-4"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="edu-blog blog-style-list" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-28.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content">
                                        <h5 class="title"><a href="blog-details.html">Fresh Inspiration For March And A Smashing...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adip isicing sed eiusmod.</p>
                                        <div class="read-more-btn">
                                            <a class="edu-btn btn-border btn-medium" href="blog-details.html">Learn More <i class="icon-4"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="edu-blog blog-style-list" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-29.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content">
                                        <h5 class="title"><a href="blog-details.html">Fresh Inspiration For March And A Smashing...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adip isicing sed eiusmod.</p>
                                        <div class="read-more-btn">
                                            <a class="edu-btn btn-border btn-medium" href="blog-details.html">Learn More <i class="icon-4"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="edu-blog blog-style-list" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-30.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content">
                                        <h5 class="title"><a href="blog-details.html">How to Developers Taking the Guess Work...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adip isicing sed eiusmod.</p>
                                        <div class="read-more-btn">
                                            <a class="edu-btn btn-border btn-medium" href="blog-details.html">Learn More <i class="icon-4"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="edu-blog blog-style-list" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-31.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content">
                                        <h5 class="title"><a href="blog-details.html">Ten Benefits Of Rentals That May Change...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adip isicing sed eiusmod.</p>
                                        <div class="read-more-btn">
                                            <a class="edu-btn btn-border btn-medium" href="blog-details.html">Learn More <i class="icon-4"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ul class="edu-pagination top-space-30 justify-content-start">
                                <li>
                                    <a href="#" aria-label="Previous"><i class="icon-west"></i></a>
                                </li>
                                <li class="active"><a href="#">1</a></li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                                <li class="more-next"><a href="#"></a></li>
                                <li><a href="#">8</a></li>
                                <li>
                                    <a href="#" aria-label="Next"><i class="icon-east"></i></a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-4">
                            <div class="edu-blog-sidebar">

                                <div class="edu-blog-widget widget-search">
                                    <div class="inner">
                                        <h4 class="widget-title">Search</h4>
                                        <div class="content">
                                            <form class="blog-search" action="#">
                                                <button class="search-button"><i class="icon-2"></i></button>
                                                <input type="text" placeholder="Search" />
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div class="edu-blog-widget widget-latest-post">
                                    <div class="inner">
                                        <h4 class="widget-title">Latest Post</h4>
                                        <div class="content latest-post-list">
                                            <div class="latest-post">
                                                <div class="thumbnail">
                                                    <a href="blog-details.html">
                                                        <img src="/images/blog/small-post-01.jpg" alt="Blog Images" />
                                                    </a>
                                                </div>
                                                <div class="post-content">
                                                    <h6 class="title"><a href="blog-details.html">Instructional Design &amp; Adult Learners</a></h6>
                                                    <ul class="blog-meta">
                                                        <li><i class="icon-27"></i>22 Nov, 2024</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="latest-post">
                                                <div class="thumbnail">
                                                    <a href="blog-details.html">
                                                        <img src="/images/blog/small-post-02.jpg" alt="Blog Images" />
                                                    </a>
                                                </div>
                                                <div class="post-content">
                                                    <h6 class="title"><a href="blog-details.html">Believe in Yourself! Have Faith!</a></h6>
                                                    <ul class="blog-meta">
                                                        <li><i class="icon-27"></i>22 Nov, 2024</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="latest-post">
                                                <div class="thumbnail">
                                                    <a href="blog-details.html">
                                                        <img src="/images/blog/small-post-03.jpg" alt="Blog Images" />
                                                    </a>
                                                </div>
                                                <div class="post-content">
                                                    <h6 class="title"><a href="blog-details.html">Live Online Lessons & eLearning</a></h6>
                                                    <ul class="blog-meta">
                                                        <li><i class="icon-27"></i>22 Nov, 2024</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="edu-blog-widget widget-categories">
                                    <div class="inner">
                                        <h4 class="widget-title">Categories</h4>
                                        <div class="content">
                                            <ul class="category-list">
                                                <li>
                                                    <a href="#">Business Studies <span>(3)</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">Computer Engineering <span>(7)</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">Medical &amp; Health<span>(2)</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">Software <span>(1)</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">Web Development <span>(3)</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">Uncategorized <span>(9)</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="edu-blog-widget widget-action">
                                    <div class="inner">
                                        <h4 class="title">Get Online Courses From <span>EduBlink</span></h4>
                                        <span class="shape-line"><i class="icon-19"></i></span>
                                        <p>Nostrud exer ciation laboris aliqup</p>
                                        <a href="#" class="edu-btn btn-medium">Start Now <i class="icon-4"></i></a>
                                    </div>
                                </div>

                                <div class="edu-blog-widget widget-categories">
                                    <div class="inner">
                                        <h4 class="widget-title">Archives</h4>
                                        <div class="content">
                                            <ul class="category-list">
                                                <li>
                                                    <a href="#">2017 Nevember <span>(3)</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">2018 December <span>(7)</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">2019 January<span>(2)</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">2020 February <span>(1)</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">2024 March <span>(3)</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="edu-blog-widget widget-tags">
                                    <div class="inner">
                                        <h4 class="widget-title">Tags</h4>
                                        <div class="content">
                                            <div class="tag-list">
                                                <a href="#">Language</a>
                                                <a href="#">eLearn</a>
                                                <a href="#">Tips</a>
                                                <a href="#">Course</a>
                                                <a href="#">Motivation</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="edu-cta-banner-area home-one-cta-wrapper bg-image">
                <div class="container">
                    <div class="edu-cta-banner">
                        <div class="row justify-content-center">
                            <div class="col-lg-7">
                                <div class="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <h2 class="title">Get Your Quality Skills <span class="color-secondary">Certificate</span> Through EduBlink</h2>
                                    <a href="contact-us.html" class="edu-btn">Get started now <i class="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                        <ul class="shape-group">
                            <li class="shape-01 scene">
                                <img data-depth="2.5" src="/images/cta/shape-10.png" alt="shape" />
                            </li>
                            <li class="shape-02 scene">
                                <img data-depth="-2.5" src="/images/cta/shape-09.png" alt="shape" />
                            </li>
                            <li class="shape-03 scene">
                                <img data-depth="-2" src="/images/cta/shape-08.png" alt="shape" />
                            </li>
                            <li class="shape-04 scene">
                                <img data-depth="2" src="/images/about/shape-13.png" alt="shape" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div> */}





            <div class="edu-breadcrumb-area">
                <div class="container">
                    <div class="breadcrumb-inner">
                        <div class="page-title">
                            <h1 class="title">Blog Masonry</h1>
                        </div>
                        <ul class="edu-breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li class="separator"><i class="icon-angle-right"></i></li>
                            <li class="breadcrumb-item"><a href="#">Pages</a></li>
                            <li class="separator"><i class="icon-angle-right"></i></li>
                            <li class="breadcrumb-item active" aria-current="page">Blog 2</li>
                        </ul>
                    </div>
                </div>
                <ul class="shape-group">
                    <li class="shape-1">
                        <span></span>
                    </li>
                    <li class="shape-2 scene"><img data-depth="2" src="/images/about/shape-13.png" alt="shape" /></li>
                    <li class="shape-3 scene"><img data-depth="-2" src="/images/about/shape-15.png" alt="shape" /></li>
                    <li class="shape-4">
                        <span></span>
                    </li>
                    <li class="shape-5 scene"><img data-depth="2" src="/images/about/shape-07.png" alt="shape" /></li>
                </ul>
            </div>


            <section class="section-gap-equal">
                <div class="container">
                    <div class="row g-5" id="masonry-gallery">

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-01.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="blog-details.html"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">ONLINE</a>
                                        </div>
                                        <h5 class="title"><a href="blog-details.html">Become a Better Blogger: Content Planning</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-02.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="blog-details.html"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Lecture</a>
                                        </div>
                                        <h5 class="title"><a href="blog-details.html">Fresh Inspiration For March And A...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed do eiusmod ux tempor incid idunt labore dol oremagna aliqua.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-03.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="blog-details.html"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Business</a>
                                        </div>
                                        <h5 class="title"><a href="blog-details.html">How to Developers Taking the Guess Work...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed do eiusmod ux tempor incid idunt.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-19.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="blog-details.html"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Business</a>
                                        </div>
                                        <h5 class="title"><a href="blog-details.html">How to Become Computer Working Days...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed do eiusmod ux tempor incid idunt labore ad dolore magna aliqua enim mini veniam quis nostrud exercitation.ullamco laboris.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-21.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="blog-details.html"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Lecture</a>
                                        </div>
                                        <h5 class="title"><a href="blog-details.html">Designing an Online Course from...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed do eiusmod ux tempor incid idunt labore dol oremagna aliqua.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-20.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="blog-details.html"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Online</a>
                                        </div>
                                        <h5 class="title"><a href="blog-details.html">Ten Benefits Of Rentals That May Change...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-23.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="blog-details.html"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Bussiness</a>
                                        </div>
                                        <h5 class="title"><a href="blog-details.html">How to Keep Workouts Fresh in the...</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed do eiusmod ux tempor incid idunt labore ad dolore magna aliqua enim mini veniam quis nostrud exercitation.ullamco laboris.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-24.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="blog-details.html"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Online</a>
                                        </div>
                                        <h5 class="title"><a href="blog-details.html">Become a Better Blogger: Content Planning</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-12 masonry-item" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                            <div class="edu-blog blog-style-5">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="blog-details.html">
                                            <img src="/images/blog/blog-22.jpg" alt="Blog Images" />
                                        </a>
                                    </div>
                                    <div class="content position-top">
                                        <div class="read-more-btn">
                                            <a class="btn-icon-round" href="blog-details.html"><i class="icon-4"></i></a>
                                        </div>
                                        <div class="category-wrap">
                                            <a href="#" class="blog-category">Lecture</a>
                                        </div>
                                        <h5 class="title"><a href="blog-details.html">Unveiling the Secrets of Online Teaching</a></h5>
                                        <ul class="blog-meta">
                                            <li><i class="icon-27"></i>Oct 10, 2024</li>
                                            <li><i class="icon-28"></i>Com 09</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet cons tetur adipisicing sed do eiusmod ux tempor incid idunt.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <ul class="edu-pagination top-space-30">
                        <li>
                            <a href="#" aria-label="Previous"><i class="icon-west"></i></a>
                        </li>
                        <li class="active"><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li class="more-next"><a href="#"></a></li>
                        <li><a href="#">8</a></li>
                        <li>
                            <a href="#" aria-label="Next"><i class="icon-east"></i></a>
                        </li>
                    </ul>
                </div>
            </section>

            <div class="edu-cta-banner-area home-one-cta-wrapper bg-image">
                <div class="container">
                    <div class="edu-cta-banner">
                        <div class="row justify-content-center">
                            <div class="col-lg-7">
                                <div class="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <h2 class="title">Get Your Quality Skills <span class="color-secondary">Certificate</span> Through EduBlink</h2>
                                    <a href="contact-us.html" class="edu-btn">Get started now <i class="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                        <ul class="shape-group">
                            <li class="shape-01 scene">
                                <img data-depth="2.5" src="/images/cta/shape-10.png" alt="shape" />
                            </li>
                            <li class="shape-02 scene">
                                <img data-depth="-2.5" src="/images/cta/shape-09.png" alt="shape" />
                            </li>
                            <li class="shape-03 scene">
                                <img data-depth="-2" src="/images/cta/shape-08.png" alt="shape" />
                            </li>
                            <li class="shape-04 scene">
                                <img data-depth="2" src="/images/about/shape-13.png" alt="shape" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </div>
    </>)

}


export default BlogList