import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Container/Home';
import ContactUs from './Container/ContactUs';
import AboutUs from './Container/Aboutus';
import BlogList from './Container/Blog';
import Gallery from './Container/Gallery';
import ProductDetail from './Container/ProductDetail';
import ProductList from './Container/product_list.js';

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/blog" element={<BlogList />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/product/:slug?" element={<ProductDetail />} />
      <Route path="/product-list/:type?" element={<ProductList />} />
    </Routes>
  </Router>
  );
}

export default App;
