import axios from 'axios';
import constant from './constant';
const client = axios.create({
  baseURL: constant.API_URL,
});
function getauthtoken(){
  let token = localStorage.getItem('USER_TOKEN');
  let Authtoken = '';
  if(token !=null && token !='' && token !=undefined){
      Authtoken = token;
  }
  const config = {
    headers: { 'X-Authorization': `Bearer ${Authtoken}`, 'X-AuthToken': `Bearer fc892555c9647c7ced15a9c7fb8c4a3640f3e83d401ca7cbf347748738ca3638` }
  };
  return config;
}
function getauthtemptoken(){
  let token = localStorage.getItem('TEMP_USER_TOKEN');
  let Authtoken = '';
  if(token !=null && token !='' && token !=undefined){
      Authtoken = token;
  }
  const config = {
    headers: { 'X-Authorization': `Bearer ${Authtoken}`, 'X-AuthToken': `Bearer fc892555c9647c7ced15a9c7fb8c4a3640f3e83d401ca7cbf347748738ca3638` }
  };
  return config;
}

export class ApiService {
  static async fetchData(url) {
    try {
      const response = await client.get(url, getauthtoken());
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async postData(url,data) {
    try {
      const response = await client.post(url, data, getauthtoken());
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  static async loginProccessPostData(url,data) {
    try {
      const response = await client.post(url, data, getauthtemptoken());
      return response.data;
    } catch (error) {
      // console.error('Error posting data:', error);
      throw error;
    }
  }
  static async filterPostData(url,data) {
    try {
      const response = await client.post(url, data, getauthtoken());
      return response.data;
    } catch (error) {
      //console.error('Error posting data:', error);
      throw error;
    }
  }

  static async customUrlGetData(url,data) {
    try {
      const response = await client.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  

  static async numberFormat(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}



