import React, { useContext } from 'react';
import DataContext from '../Elements/context';

const Footer = () => {
  const contextValues= useContext(DataContext)
  return (
    <footer className="edu-footer footer-lighten bg-image footer-style-1">
      <div className="footer-top">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <div className="edu-footer-widget">
                <div className="logo">
                  <a href="/">
                  <img
                        className="logo-light"
                        src="/images/logo_white.png"
                        alt="Corporate Logo"
                        style={{ height: '88px' }}
                      />
                    {/* {contextValues?.settingData?.white_logo && (
                      <img
                        className="logo-light"
                        src='/images/logo.png'
                        alt="Corporate Logo"
                        style={{ height: '88px' }}
                      />
                    )} */}
                    <img
                      className="logo-dark"
                      src="/images/logo_white.png"
                      alt="Corporate Logo"
                    />
                  </a>
                </div>
                <p className="description">
                  Lorem ipsum dolor amet consecto adi pisicing elit sed eiusm tempor incidid unt labore dolore.
                </p>
                <div className="widget-information">
                  <ul className="information-list">
                    {contextValues?.settingData?.address && (
                      <li>
                        <span>Add:</span> {contextValues?.settingData.address}
                      </li>
                    )}
                    {contextValues?.settingData?.admin_support_mobile && (
                      <li>
                        <span>Call:</span>
                        <a href={`tel:${contextValues?.settingData.admin_support_mobile}`}>
                          {contextValues?.settingData.admin_support_mobile}
                        </a>
                      </li>
                    )}
                    {contextValues?.settingData?.admin_support_email && (
                      <li>
                        <span>Email:</span>
                        <a
                          href={`mailto:${contextValues?.settingData.admin_support_email}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {contextValues?.settingData.admin_support_email}
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="edu-footer-widget explore-widget">
                <h4 className="widget-title">Our Products</h4>
                <div className="inner">
                  <ul className="footer-link link-hover">
                   <li> <a href="/">IVD Kits</a></li>
                   <li> <a href="/">Research Kits</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="edu-footer-widget quick-link-widget">
                <h4 className="widget-title">Quick Links</h4>
                <div className="inner">
                  <ul className="footer-link link-hover">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/about-us">About Us</a>
                    </li>
                    <li>
                      <a href="#">Blog</a>
                    </li>
                    <li>
                      <a href="/contact-us">Contact Us</a>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="edu-footer-widget">
                <h4 className="widget-title">Newsletter</h4>
                <div className="inner">
                  <p className="description">
                    Enter your email address to register to our newsletter subscription
                  </p>
                  <div className="input-group footer-subscription-form">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your email"
                    />
                    <button className="edu-btn btn-medium" type="button">
                      Subscribe <i className="icon-4"></i>
                    </button>
                  </div>
                  <ul className="social-share icon-transparent">
                
                    {contextValues?.settingData?.facebook_url &&  <li><a href={contextValues?.settingData?.facebook_url} target='new' className="color-fb"><i className="icon-facebook"></i></a></li> }
                    {contextValues?.settingData?.instagram_url &&  <li><a href={contextValues?.settingData?.instagram_url} target='new' className="color-ig"><i className="icon-instagram"></i></a></li> }
                    {contextValues?.settingData?.twitter_url &&  <li><a href={contextValues?.settingData?.twitter_url} target='new' className="color-twitter"><i className="icon-twitter"></i></a></li> }
                    {contextValues?.settingData?.linkedin_url &&  <li><a href={contextValues?.settingData?.linkedin_url} target='new' className="color-linkd"><i className="icon-linkedin2"></i></a></li> }
                    {contextValues?.settingData?.youtube_url &&  <li><a href={contextValues?.settingData?.youtube_url} target='new' className="color-yt"> <i className="icon-youtube"></i></a></li> }
                  
                   
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <p>
                  Copyright 2024 <a href="/">Gnovik</a>. All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
