import React, { useState, useEffect, useRef } from "react";
import { ApiService } from '../Component/Elements/Services/apiservices'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import constant from "../Component/Elements/Services/constant";

const Testimonial = () => {
    const [testimonialData, setTestimonialData] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [testimonialImgUrl, settestimonialImgUrl] = useState('')
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            getTestimonialData();
        }
        didMountRef.current = false
    }, []);
    const getTestimonialData = () => {
        setisLoading(true)
        ApiService.fetchData('featuredTestimonials').then((res) => {
            if (res.status === 'success') {
                setTestimonialData(res.testimonialData)
                settestimonialImgUrl(res.testimonial_image_path)
                setisLoading(false)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }
    return (


        <div class="testimonial-area-10 gap-top-equal photography-testimonial">
            <div class="container edublink-animated-shape">
                <div class="testimonial-heading-area">
                    <div class="section-title section-center" data-sal-delay="50" data-sal="slide-up" data-sal-duration="800">
                        <span class="pre-title">Testimonials</span>
                        <h2 class="title">Trusted Voices in Clinical Excellence</h2>
                        {/* <p>Hear from our satisfied clients about their experiences with our high-quality IVD kits, Research Kits, and advanced solutions</p> */}
                        <span class="shape-line"><i class="icon-19"></i></span>
                    </div>
                </div>
                <div class="photography-testimonial-activator swiper">
                    <div class="swiper-wrapper">
                        {testimonialData?.map((item, index) => {
                            return (<>
                                <div class="swiper-slide" key={index}>
                                    <div class="testimonial-grid">
                                        <div class="content">
                                            <div class="quote-icon">
                                                <img src="/images/svg-icons/quote.svg" alt="quote svg" />
                                            </div>
                                            {item?.testimonial_desc && <p dangerouslySetInnerHTML={{ __html: item?.testimonial_desc }}></p>}
                                            {item?.testimonial_rating && (
                                                <div class="rating-icon">
                                                    {Array.from({ length: item?.testimonial_rating }).map((_, index) => (
                                                        <i class="icon-23" key={index}></i>
                                                    ))}
                                                </div>
                                            )}
                                            {item?.testimonial_name && <h5 class="title">{item?.testimonial_name}</h5>}
                                            {item?.testimonial_designation && <span class="subtitle">{item?.testimonial_designation}</span>}

                                        </div>
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                </div>
                <div class="swiper photography-testimonial-thumbs photo-testimonial-thumb-wrap">
                    <div class="swiper-wrapper">
                        {testimonialData?.map((item, index) => {
                            return (<>
                                <div class="nav-thumb swiper-slide" key={index}>
                                    <div class="clint-thumb">
                                        <img src={item?.testimonial_image ? testimonialImgUrl + item?.testimonial_image : constant.DEFAULT_IMAGE} alt="Testimonial" loading="" />
                                    </div>
                                </div>
                            </>)
                        })}
                    </div>
                </div>
                <div class="swiper-slide-controls slide-prev">
                    <i class="icon-west"></i>
                </div>
                <div class="swiper-slide-controls slide-next">
                    <i class="icon-east"></i>
                </div>

                <ul class="shape-group">
                    <li class="shape-1 scene" data-sal-delay="1000" data-sal="fade" data-sal-duration="1000">
                        <img data-depth="-1.5" src="/images/about/shape-13.png" alt="Shape" />
                    </li>
                    <li class="shape-2 sal-animate" data-sal-delay="200" data-sal="fade" data-sal-duration="1000">
                        <img src="/images/others/Photo-shape-6.png" alt="Shape" />
                    </li>
                    <li class="shape-3 sal-animate" data-sal-delay="200" data-sal="fade" data-sal-duration="1000">
                        <img class="d-block-shape-light" src="/images/others/map-shape-3.png" alt="Shape" />
                        <img class="d-none-shape-dark" src="/images/others/dark-map-2-shape-3.png" alt="Shape" />
                    </li>
                    <li class="shape-4" data-sal-delay="1000" data-sal="fade" data-sal-duration="1000">
                        <img class="rotateit" src="/images/about/shape-37.png" alt="Shape" />
                    </li>
                    <li class="shape-5">
                        <span></span>
                    </li>
                </ul>
            </div>
        </div>

    )
}

export default Testimonial