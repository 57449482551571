const ProductCounter=()=>{
    return(<>
    <div class="counterup-area-1 gap-lg-bottom-equal">
    <div class="container">
        <div class="row g-5">
            <div class="col-lg-3 col-sm-6" data-sal-delay="50" data-sal="slide-up" data-sal-duration="800">
                <div class="edu-counterup counterup-style-1">
                    <h2 class="counter-item count-number primary-color"><span class="odometer" data-odometer-final="29.3">.</span><span>K</span></h2>
                    <h6 class="title">Products Sold</h6>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                <div class="edu-counterup counterup-style-1">
                    <h2 class="counter-item count-number secondary-color"><span class="odometer" data-odometer-final="32.4">.</span><span>K</span></h2>
                    <h6 class="title">Satisfaction Rate</h6>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                <div class="edu-counterup counterup-style-1">
                    <h2 class="counter-item count-number extra02-color"><span class="odometer" data-odometer-final="100">.</span><span>%</span></h2>
                   
                    <h6 class="title">Products Sold</h6>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                <div class="edu-counterup counterup-style-1">
                    <h2 class="counter-item count-number extra05-color"><span class="odometer" data-odometer-final="354">.</span><span>+</span></h2>
                    <h6 class="title">Satisfaction Rate</h6>
                </div>
            </div>
        </div>
    </div>
</div>

    
    
    </>)
}


export default ProductCounter