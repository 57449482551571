import Footer from "../../Component/Footer"
import Header from "../../Component/Header"

const ProductList = () => {
    return (<>
        <Header></Header>
        <div class="edu-breadcrumb-area">
            <div class="container">
                <div class="breadcrumb-inner">
                    <div class="page-title">
                        <h1 class="title">Shop Page</h1>
                    </div>
                    <ul class="edu-breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="separator"><i class="icon-angle-right"></i></li>
                        <li class="breadcrumb-item"><a href="#">Pages</a></li>
                        <li class="separator"><i class="icon-angle-right"></i></li>
                        <li class="breadcrumb-item active" aria-current="page">Shop</li>
                    </ul>
                </div>
            </div>
            <ul class="shape-group">
                <li class="shape-1">
                    <span></span>
                </li>
                <li class="shape-2 scene"><img data-depth="2" src="/images/about/shape-13.png" alt="shape" /></li>
                <li class="shape-3 scene"><img data-depth="-2" src="/images/about/shape-15.png" alt="shape" /></li>
                <li class="shape-4">
                    <span></span>
                </li>
                <li class="shape-5 scene"><img data-depth="2" src="/images/about/shape-07.png" alt="shape" /></li>
            </ul>
        </div>


        <section class="shop-page-area section-gap-equal">
            <div class="container">
                <div class="edu-sorting-area">
                    <div class="sorting-left">
                        <h6 class="showing-text">We found <span>71</span> courses available for you</h6>
                    </div>
                    <div class="sorting-right">
                        <div class="edu-sorting">
                            <div class="icon"><i class="icon-55"></i></div>
                            <select class="edu-select">
                                <option>Filters</option>
                                <option>Low To High</option>
                                <option>High Low To</option>
                                <option>Last Viewed</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-lg-3 col-md-4 col-sm-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                        <div class="edu-product">
                            <div class="inner">
                                <div class="thumbnail">
                                    <a href="product-details.html">
                                        <img src="/images/shop/product-01.jpg" alt="Shop Images" />
                                    </a>
                                    <div class="product-hover-info">
                                        <ul>
                                            <li>
                                                <a data-bs-toggle="modal" href="#pro-quick-view" role="button"><i class="icon-2"></i></a>
                                            </li>
                                            <li>
                                                <a href="wishlist.html"><i class="icon-22"></i></a>
                                            </li>
                                            <li>
                                                <a href="cart.html"><i class="icon-3"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="product-details.html">Sing To It</a></h6>
                                    <div class="product-rating">
                                        <div class="rating">
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                        </div>
                                        <span class="rating-count">(3)</span>
                                    </div>
                                    <div class="price">$70.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div class="edu-product">
                            <div class="inner">
                                <div class="thumbnail">
                                    <a href="product-details.html">
                                        <img src="/images/shop/product-02.jpg" alt="Shop Images" />
                                    </a>
                                    <div class="product-hover-info">
                                        <ul>
                                            <li>
                                                <a data-bs-toggle="modal" href="#pro-quick-view" role="button"><i class="icon-2"></i></a>
                                            </li>
                                            <li>
                                                <a href="wishlist.html"><i class="icon-22"></i></a>
                                            </li>
                                            <li>
                                                <a href="cart.html"><i class="icon-3"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="product-details.html">Natural Science Project</a></h6>
                                    <div class="product-rating">
                                        <div class="rating">
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                        </div>
                                        <span class="rating-count">(3)</span>
                                    </div>
                                    <div class="price">$70.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                        <div class="edu-product">
                            <div class="inner">
                                <div class="thumbnail">
                                    <a href="product-details.html">
                                        <img src="/images/shop/product-03.jpg" alt="Shop Images" />
                                    </a>
                                    <div class="product-hover-info">
                                        <ul>
                                            <li>
                                                <a data-bs-toggle="modal" href="#pro-quick-view" role="button"><i class="icon-2"></i></a>
                                            </li>
                                            <li>
                                                <a href="wishlist.html"><i class="icon-22"></i></a>
                                            </li>
                                            <li>
                                                <a href="cart.html"><i class="icon-3"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="product-details.html">The King of Drugs</a></h6>
                                    <div class="product-rating">
                                        <div class="rating">
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                        </div>
                                        <span class="rating-count">(3)</span>
                                    </div>
                                    <div class="price">$70.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" data-sal-delay="250" data-sal="slide-up" data-sal-duration="800">
                        <div class="edu-product">
                            <div class="inner">
                                <div class="thumbnail">
                                    <a href="product-details.html">
                                        <img src="/images/shop/product-04.jpg" alt="Shop Images" />
                                    </a>
                                    <div class="product-hover-info">
                                        <ul>
                                            <li>
                                                <a data-bs-toggle="modal" href="#pro-quick-view" role="button"><i class="icon-2"></i></a>
                                            </li>
                                            <li>
                                                <a href="wishlist.html"><i class="icon-22"></i></a>
                                            </li>
                                            <li>
                                                <a href="cart.html"><i class="icon-3"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="product-details.html">Ray Brandbury</a></h6>
                                    <div class="product-rating">
                                        <div class="rating">
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                        </div>
                                        <span class="rating-count">(3)</span>
                                    </div>
                                    <div class="price">$70.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" data-sal-delay="300" data-sal="slide-up" data-sal-duration="800">
                        <div class="edu-product">
                            <div class="inner">
                                <div class="thumbnail">
                                    <a href="product-details.html">
                                        <img src="/images/shop/product-05.jpg" alt="Shop Images" />
                                    </a>
                                    <div class="product-hover-info">
                                        <ul>
                                            <li>
                                                <a data-bs-toggle="modal" href="#pro-quick-view" role="button"><i class="icon-2"></i></a>
                                            </li>
                                            <li>
                                                <a href="wishlist.html"><i class="icon-22"></i></a>
                                            </li>
                                            <li>
                                                <a href="cart.html"><i class="icon-3"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="product-details.html">Educated A Memoir</a></h6>
                                    <div class="product-rating">
                                        <div class="rating">
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                        </div>
                                        <span class="rating-count">(3)</span>
                                    </div>
                                    <div class="price">$70.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" data-sal-delay="350" data-sal="slide-up" data-sal-duration="800">
                        <div class="edu-product">
                            <div class="inner">
                                <div class="thumbnail">
                                    <a href="product-details.html">
                                        <img src="/images/shop/product-06.jpg" alt="Shop Images" />
                                    </a>
                                    <div class="product-hover-info">
                                        <ul>
                                            <li>
                                                <a data-bs-toggle="modal" href="#pro-quick-view" role="button"><i class="icon-2"></i></a>
                                            </li>
                                            <li>
                                                <a href="wishlist.html"><i class="icon-22"></i></a>
                                            </li>
                                            <li>
                                                <a href="cart.html"><i class="icon-3"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="product-details.html">The Silver Chair</a></h6>
                                    <div class="product-rating">
                                        <div class="rating">
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                        </div>
                                        <span class="rating-count">(3)</span>
                                    </div>
                                    <div class="price">$70.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" data-sal-delay="400" data-sal="slide-up" data-sal-duration="800">
                        <div class="edu-product">
                            <div class="inner">
                                <div class="thumbnail">
                                    <a href="product-details.html">
                                        <img src="/images/shop/product-07.jpg" alt="Shop Images" />
                                    </a>
                                    <div class="product-hover-info">
                                        <ul>
                                            <li>
                                                <a data-bs-toggle="modal" href="#pro-quick-view" role="button"><i class="icon-2"></i></a>
                                            </li>
                                            <li>
                                                <a href="wishlist.html"><i class="icon-22"></i></a>
                                            </li>
                                            <li>
                                                <a href="cart.html"><i class="icon-3"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="product-details.html">Harry Potter</a></h6>
                                    <div class="product-rating">
                                        <div class="rating">
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                        </div>
                                        <span class="rating-count">(3)</span>
                                    </div>
                                    <div class="price">$70.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" data-sal-delay="450" data-sal="slide-up" data-sal-duration="800">
                        <div class="edu-product">
                            <div class="inner">
                                <div class="thumbnail">
                                    <a href="product-details.html">
                                        <img src="/images/shop/product-08.jpg" alt="Shop Images" />
                                    </a>
                                    <div class="product-hover-info">
                                        <ul>
                                            <li>
                                                <a data-bs-toggle="modal" href="#pro-quick-view" role="button"><i class="icon-2"></i></a>
                                            </li>
                                            <li>
                                                <a href="wishlist.html"><i class="icon-22"></i></a>
                                            </li>
                                            <li>
                                                <a href="cart.html"><i class="icon-3"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="product-details.html">Code Breaker</a></h6>
                                    <div class="product-rating">
                                        <div class="rating">
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                        </div>
                                        <span class="rating-count">(3)</span>
                                    </div>
                                    <div class="price">$70.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" data-sal-delay="500" data-sal="slide-up" data-sal-duration="800">
                        <div class="edu-product">
                            <div class="inner">
                                <div class="thumbnail">
                                    <a href="product-details.html">
                                        <img src="/images/shop/product-09.jpg" alt="Shop Images" />
                                    </a>
                                    <div class="product-hover-info">
                                        <ul>
                                            <li>
                                                <a data-bs-toggle="modal" href="#pro-quick-view" role="button"><i class="icon-2"></i></a>
                                            </li>
                                            <li>
                                                <a href="wishlist.html"><i class="icon-22"></i></a>
                                            </li>
                                            <li>
                                                <a href="cart.html"><i class="icon-3"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="product-details.html">Vanguard</a></h6>
                                    <div class="product-rating">
                                        <div class="rating">
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                        </div>
                                        <span class="rating-count">(3)</span>
                                    </div>
                                    <div class="price">$70.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" data-sal-delay="550" data-sal="slide-up" data-sal-duration="800">
                        <div class="edu-product">
                            <div class="inner">
                                <div class="thumbnail">
                                    <a href="product-details.html">
                                        <img src="/images/shop/product-10.jpg" alt="Shop Images" />
                                    </a>
                                    <div class="product-hover-info">
                                        <ul>
                                            <li>
                                                <a data-bs-toggle="modal" href="#pro-quick-view" role="button"><i class="icon-2"></i></a>
                                            </li>
                                            <li>
                                                <a href="wishlist.html"><i class="icon-22"></i></a>
                                            </li>
                                            <li>
                                                <a href="cart.html"><i class="icon-3"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="product-details.html">Arctic Sea</a></h6>
                                    <div class="product-rating">
                                        <div class="rating">
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                        </div>
                                        <span class="rating-count">(3)</span>
                                    </div>
                                    <div class="price">$70.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" data-sal-delay="600" data-sal="slide-up" data-sal-duration="800">
                        <div class="edu-product">
                            <div class="inner">
                                <div class="thumbnail">
                                    <a href="product-details.html">
                                        <img src="/images/shop/product-11.jpg" alt="Shop Images" />
                                    </a>
                                    <div class="product-hover-info">
                                        <ul>
                                            <li>
                                                <a data-bs-toggle="modal" href="#pro-quick-view" role="button"><i class="icon-2"></i></a>
                                            </li>
                                            <li>
                                                <a href="wishlist.html"><i class="icon-22"></i></a>
                                            </li>
                                            <li>
                                                <a href="cart.html"><i class="icon-3"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="product-details.html">Secret Sky</a></h6>
                                    <div class="product-rating">
                                        <div class="rating">
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                        </div>
                                        <span class="rating-count">(3)</span>
                                    </div>
                                    <div class="price">$70.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6" data-sal-delay="650" data-sal="slide-up" data-sal-duration="800">
                        <div class="edu-product">
                            <div class="inner">
                                <div class="thumbnail">
                                    <a href="product-details.html">
                                        <img src="/images/shop/product-12.jpg" alt="Shop Images" />
                                    </a>
                                    <div class="product-hover-info">
                                        <ul>
                                            <li>
                                                <a data-bs-toggle="modal" href="#pro-quick-view" role="button"><i class="icon-2"></i></a>
                                            </li>
                                            <li>
                                                <a href="wishlist.html"><i class="icon-22"></i></a>
                                            </li>
                                            <li>
                                                <a href="cart.html"><i class="icon-3"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="product-details.html">Women Who Launch</a></h6>
                                    <div class="product-rating">
                                        <div class="rating">
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                            <i class="icon-23"></i>
                                        </div>
                                        <span class="rating-count">(3)</span>
                                    </div>
                                    <div class="price">$70.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ul class="edu-pagination pt--50">
                    <li>
                        <a href="#" aria-label="Previous"><i class="icon-west"></i></a>
                    </li>
                    <li class="active"><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li class="more-next"><a href="#"></a></li>
                    <li><a href="#">8</a></li>
                    <li>
                        <a href="#" aria-label="Next"><i class="icon-east"></i></a>
                    </li>
                </ul>
            </div>
        </section>
        <Footer></Footer>

    </>)
}


export default ProductList