import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import Testimonial from "../testimonial"
import AboveFooter from "./above_footer_sec"
import BannerSection from "./banner_below_sec"
import AboutUs from "./home_aboutus"
import HomeBanner from "./hone_banner"
import PopularProducts from "./popular_products"
import ProductCounter from "./product_counter"

const Home=()=>{
    return(<>
      <div id="main-wrapper" class="main-wrapper">
      <Header></Header>
        <HomeBanner></HomeBanner>
        {/* <BannerSection></BannerSection> */}
        <AboutUs></AboutUs>
        <ProductCounter></ProductCounter>
        <PopularProducts></PopularProducts>
        <Testimonial></Testimonial>
        <AboveFooter></AboveFooter>
    <Footer></Footer>
      </div>
   
    </>)
}
export default Home