import { useContext, useState } from "react"
import Footer from "../../Component/Footer"
import Header from "../../Component/Header"
import DataContext from "../../Component/Elements/context"
import { ApiService } from "../../Component/Elements/Services/apiservices";
import { validEmail, validNumber } from "../../Component/Elements/Regex";



const ContactUs = () => {
    const contextValues = useContext(DataContext)
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [contactData, setContactData] = useState({
        contact_name: "",
        contact_email: "",
        contact_mobile: '',
        contact_message: ''
    })

    const handleInputChange = (e) => {
        setErrorMessage('')
        setSuccessMessage('')
        const { name, value } = e.target;
        setContactData(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };

    const handleSubmit = () => {
        setErrorMessage('')
        setSuccessMessage('')
        if (!contactData.contact_name) {
            setErrorMessage('Please Enter Name')
            return false;
        }
        if (!contactData.contact_email) {
            setErrorMessage('Please Enter Email')
            return false;
        }
        if (!validEmail.test(contactData.contact_email)) {
            setErrorMessage('Invalid Email Format')
            return false;
        }
        if (!contactData.contact_mobile) {
            setErrorMessage('Please Enter Mobile Number')
            return false;
        }
        if (!validNumber.test(contactData.contact_mobile)) {
            setErrorMessage('Invalid Mobile Number ')
            return false;
        }
        if (!contactData.contact_message) {
            setErrorMessage('Please Enter Message')
            return false;
        }
        ApiService.postData("contactusprocess", contactData).then((res) => {
            if (res.status == "success") {
                setSuccessMessage(res.message);
                setTimeout(() => {
                    window.location.reload()
                   
                }, 2000);
            } else {
                setErrorMessage(res.message);
            }
        }).catch(() => {
        })
    }
    return (<>

        <div id="main-wrapper" class="main-wrapper">

            <Header></Header>
            <div class="edu-breadcrumb-area">
                <div class="container">
                    <div class="breadcrumb-inner">
                        <div class="page-title">
                            <h1 class="title">Contact Us</h1>
                        </div>
                        <ul class="edu-breadcrumb">
                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                            <li class="separator"><i class="icon-angle-right"></i></li>
                            <li class="breadcrumb-item"><a href="/">Pages</a></li>
                            <li class="separator"><i class="icon-angle-right"></i></li>
                            <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                        </ul>
                    </div>
                </div>
                <ul class="shape-group">
                    <li class="shape-1">
                        <span></span>
                    </li>
                    <li class="shape-2 scene"><img data-depth="2" src="/images/about/shape-13.png" alt="shape" /></li>
                    <li class="shape-3 scene"><img data-depth="-2" src="/images/about/shape-15.png" alt="shape" /></li>
                    <li class="shape-4">
                        <span></span>
                    </li>
                    <li class="shape-5 scene"><img data-depth="2" src="/images/about/shape-07.png" alt="shape" /></li>
                </ul>
            </div>

            <section class="section-gap-equal contact-me-area">
                <div class="container">
                    <div class="row justify-content-between">
                        <div className="col-lg-6">
                            <div class="google-map-area">
                                <div class="mapouter">
                                    <div class="gmap_canvas">
                                        <iframe id="gmap_canvas" src={`https://maps.google.com/maps?q=${encodeURIComponent(
                                            contextValues?.settingData?.address
                                        )}&t=&z=15&ie=UTF8&iwloc=&output=embed`} frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-5">  <div class="contact-us-info">
                            <h3 class="heading-title">I will Answer all Your Questions</h3>
                            <ul class="address-list">
                                {contextValues?.settingData?.address && <li>
                                    <h5 class="title">Address</h5>
                                    <p>{contextValues?.settingData?.address}</p>
                                </li>}
                                {contextValues?.settingData?.admin_support_email &&
                                    <li>
                                        <h5 class="title">Email</h5>
                                        <p><a href={`mailto:${contextValues?.settingData?.admin_support_email}`}>{contextValues?.settingData?.admin_support_email}</a></p>
                                    </li>
                                }
                                {contextValues?.settingData?.admin_support_mobile &&
                                    <li>
                                        <h5 class="title">Phone</h5>
                                        <p><a href={`tel:${contextValues?.settingData?.admin_support_mobile}`}>{contextValues?.settingData?.admin_support_mobile}</a></p>
                                    </li>
                                }


                            </ul>
                            <ul class="social-share">
                                {contextValues?.settingData?.facebook_url && <li><a href={contextValues?.settingData?.facebook_url} target='new' ><i className="icon-facebook"></i></a></li>}
                                {contextValues?.settingData?.instagram_url && <li><a href={contextValues?.settingData?.instagram_url} target='new' ><i className="icon-instagram"></i></a></li>}
                                {contextValues?.settingData?.twitter_url && <li><a href={contextValues?.settingData?.twitter_url} target='new' ><i className="icon-twitter"></i></a></li>}
                                {contextValues?.settingData?.linkedin_url && <li><a href={contextValues?.settingData?.linkedin_url} target='new' ><i className="icon-linkedin2"></i></a></li>}
                                {contextValues?.settingData?.youtube_url && <li><a href={contextValues?.settingData?.youtube_url} target='new' > <i className="icon-youtube"></i></a></li>}

                            </ul>
                        </div></div>

                    </div>
                </div>
            </section>

            <section class="edu-section-gap contact-form-area">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="contact-form">
                                <div class="section-title section-center">
                                    <h3 class="title">Just Drop Me a Line</h3>
                                </div>

                                {errorMessage ? <div class="alert alert-danger" role="alert">{errorMessage}

                                </div> : ''}
                                {successMessage ? <div class="alert alert-success" role="alert">
                                    {successMessage}
                                </div> : ''}
                                <form class="rnt-contact-form rwt-dynamic-form" >
                                    <div class="row row--10">
                                        <div class="form-group col-lg-6">
                                            <input type="text" name="contact_name" className="contactRequired" id="contact-name" placeholder="Your Name" onChange={(e) => { handleInputChange(e) }} />
                                        </div>
                                        <div class="form-group col-lg-6">
                                            <input type="email" name="contact_email" className="contactRequired" id="contact-email" placeholder="Your Email" onChange={(e) => { handleInputChange(e) }} />
                                        </div>
                                        <div class="form-group col-12">
                                            <input type="tel" name="contact_mobile" className="contactRequired" id="contact-phone" placeholder="Phone number" onChange={(e) => { handleInputChange(e) }} />
                                        </div>
                                        <div class="form-group col-12">
                                            <textarea name="contact_message" className="contactRequired" id="contact-message" cols="30" rows="6" placeholder="Type your message" onChange={(e) => { handleInputChange(e) }}></textarea>
                                        </div>
                                        <div class="form-group col-12 text-center">
                                            <button class="rn-btn edu-btn submit-btn" onClick={(e) => { handleSubmit(e) }}>Submit Now <i class="icon-4"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="shape-group">
                    <li class="shape-1 scene"><img data-depth="-2" src="/images/about/shape-15.png" alt="shape" /></li>
                    <li class="shape-2 scene"><img data-depth="2" src="/images/cta/shape-04.png" alt="shape" /></li>
                    <li class="shape-3 scene"><span data-depth="1"></span></li>
                    <li class="shape-4 scene"><img data-depth="-2" src="/images/about/shape-13.png" alt="shape" /></li>
                </ul>
            </section>
            <Footer></Footer>
        </div>
        <div class="rn-progress-parent">
            <svg class="rn-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
            </svg>
        </div>

    </>)
}


export default ContactUs