import React, { createContext, useEffect, useState, useRef, useCallback } from 'react';
import { ApiService } from './Services/apiservices';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
 
  const didMountRef = useRef(true);
  const [settingImgPath, setsettingImgPath] = useState('');
  const [settingData, setsettingData] = useState({});
  useEffect(() => {
    if (didMountRef.current) {
      getSettingData()
    }
    didMountRef.current = false;
  }, []);
  const getSettingData = () => {
    ApiService.fetchData("settingsdata").then((res) => {
      if (res.status === "success") {
        setsettingData(res.sitesettings)
        setsettingImgPath(res.setting_image_path)
      }
    })
  }
  return (
    <DataContext.Provider value={
      {
        settingData, setsettingData,
        settingImgPath, setsettingImgPath,
      }
    }>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;