import React,{ useEffect } from "react";



const HomeBanner=()=>{

   
    return(<>
    <div class="hero-banner hero-style-3 bg-image">
        <div class="swiper university-activator">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <img data-transform-origin="center center" data-src="/images/gnovik_img/banner.webp" class="swiper-lazy" alt="image" />
                    <div class="thumbnail-bg-content">
                        <div class="container edublink-animated-shape">
                            <div class="row">
                                <div class="col-7">
                                    <div class="banner-content">
                                        <span class="subtitle" data-sal="slide-up" data-sal-duration="1000">Welcome to Genovik Biotech</span>
                                        <h1 class="title" data-sal-delay="100" data-sal="slide-up" data-sal-duration="1000">We Serve Innovative and  Quality Products For You</h1>

                                        {/* <p data-sal-delay="200" data-sal="slide-up" data-sal-duration="1000">Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit.</p> */}
                                        <div class="banner-btn" data-sal-delay="400" data-sal="slide-up" data-sal-duration="1000">
                                            <a href="#" class="edu-btn btn-secondary">Find Products <i class="icon-4"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="swiper-slide">
                    <img data-transform-origin="center center" data-src="https://www.markonik.com/public/img/uploads/appearance/1709806594.png" class="swiper-lazy" alt="image" />
                    <div class="thumbnail-bg-content">
                        <div class="container edublink-animated-shape">
                            <div class="row">
                                <div class="col-7">
                                    <div class="banner-content">
                                        <span class="subtitle" data-sal="slide-up" data-sal-duration="1000">Welcome to EduBlink</span>
                                        <h1 class="title" data-sal-delay="100" data-sal="slide-up" data-sal-duration="1000">World Best Program Best University</h1>
                                        <p data-sal-delay="200" data-sal="slide-up" data-sal-duration="1000">Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit.</p>
                                        <div class="banner-btn" data-sal-delay="400" data-sal="slide-up" data-sal-duration="1000">
                                            <a href="#" class="edu-btn btn-secondary">Find courses <i class="icon-4"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <img data-transform-origin="center center" data-src="https://www.markonik.com/public/img/uploads/appearance/1709806594.png" class="swiper-lazy" alt="image" />
                    <div class="thumbnail-bg-content">
                        <div class="container edublink-animated-shape">
                            <div class="row">
                                <div class="col-7">
                                    <div class="banner-content">
                                        <span class="subtitle" data-sal="slide-up" data-sal-duration="1000">Welcome to EduBlink</span>
                                        <h1 class="title" data-sal-delay="100" data-sal="slide-up" data-sal-duration="1000">World Best Program Best University</h1>
                                        <p data-sal-delay="200" data-sal="slide-up" data-sal-duration="1000">Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt mollit.</p>
                                        <div class="banner-btn" data-sal-delay="400" data-sal="slide-up" data-sal-duration="1000">
                                            <a href="#" class="edu-btn btn-secondary">Find courses <i class="icon-4"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div class="hero-slider-bg-controls">
                <div class="swiper-slide-controls slide-prev">
                    <i class="icon-west"></i>
                </div>
                <div class="swiper-slide-controls slide-next">
                    <i class="icon-east"></i>
                </div>
            </div>
        </div>
        <ul class="shape-group">
            <li class="shape-1 scene" data-sal-delay="1000" data-sal="fade" data-sal-duration="1000">
                <img data-depth="2" src="/images/others/shape-10.png" alt="Shape" />
            </li>
            <li class="shape-2 scene" data-sal-delay="1000" data-sal="fade" data-sal-duration="1000">
                <img data-depth="-3" src="/images/others/shape-11.png" alt="Shape" />
            </li>
            <li class="shape-3">
                <img src="/images/others/shape-25.png" alt="Shape" />
            </li>
        </ul>
    </div>
    </>)
}

export default HomeBanner









