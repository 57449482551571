import React, { useContext } from 'react';
import DataContext from '../Elements/context';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const contextValues= useContext(DataContext)
  const location = useLocation()

  return (
      <header className="edu-header header-style-1 header-fullwidth">
        {/* Top Bar */}
        <div className="header-top-bar">
          <div className="container">
            <div className="header-top">
              {/* Left Section */}
              <div className="header-top-left">
                <ul className="header-info">
                  {contextValues?.settingData?.admin_support_mobile &&  <li>
                    <a href={`tel:${contextValues?.settingData?.admin_support_mobile}`}>
                      <i className="icon-phone"></i>Call: {contextValues?.settingData?.admin_support_mobile}
                    </a>
                  </li>}
                 {contextValues?.settingData?.admin_support_email &&    <li>
                    <a href={`mailto:${contextValues?.settingData?.admin_support_email}`} target="_blank" rel="noopener noreferrer">
                      <i className="icon-envelope"></i>Email: {contextValues?.settingData?.admin_support_email}
                    </a>
                  </li>}
                 
                
              
                </ul>
              </div>
              {/* Right Section */}
              <div className="header-top-right">
                <ul className="header-info">
                  <li className="social-icon">
                    {contextValues?.settingData?.facebook_url && <a href={contextValues?.settingData?.facebook_url} target='new'><i className="icon-facebook"></i></a>}
                    {contextValues?.settingData?.instagram_url &&<a href={contextValues?.settingData?.instagram_url} target='new'><i className="icon-instagram"></i></a>}
                    {contextValues?.settingData?.twitter_url &&<a href={contextValues?.settingData?.twitter_url} target='new'><i className="icon-twitter"></i></a>}
                    {contextValues?.settingData?.linkedin_url &&<a href={contextValues?.settingData?.linkedin_url} target='new'><i className="icon-linkedin2"></i></a>}
                    
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Main Navigation */}
        <div className="header-mainmenu">
          <div className="container">
            <div className="header-navbar">
              {/* Logo Section */}
              <div className="header-brand">
                <div className="logo">
                  <a href="/">
                    <img className="logo-light" src="/images/logo_white.png" alt="Corporate Logo" />
                  </a>
                </div>
              </div>
              {/* Navigation Links */}
              <div className="header-mainnav">
                <nav className="mainmenu-nav">
                  <ul className="mainmenu">
                    <li className={location.pathname=='/'? 'active':''}><a href="/" >Home</a></li>
                    <li className={location.pathname=='/about-us'? 'active':''}><a href="/about-us">About Us</a></li>

                    <li  className={location.pathname=='/product-list'? 'active':'' + 'has-droupdown'} style={{position:"static"}}><a href="/product-list">Products</a>
                    <div className="mega-menu" >
                      <div className='container'>
                        <div className='row'>
                          <div className='col-3'>
                          <h6 class="menu-title"><a href="/product-list/ivd-kits">IVD KITS</a></h6>
                          <ul >
                            <li><a href="/product/rapid_test">Rapid Test</a></li>
                            <li><a href="/product/fia_instrument_reagents">FIA Instrument & Reagents</a></li>
                            <li><a href="/product/clinical_chemistry">Clinical Chemistry</a></li>
                            <li><a href="/product/instruments">Instruments</a></li>
                            <li><a href="/product/instructor_2">Instructor 2</a></li>
                            <li><a href="/product/elisa_kits">Elisa Kits</a></li>
                            <li><a href="/product/pcr_kits">PCR Kits</a></li>
                          </ul>
                          </div>
                          <div className='col-3'>
                          <h6 class="menu-title"><a href="/product-list/research-kits">RESEARCH KITS</a></h6>
                          <ul >
                            <li><a href="/product/elisa_kits">Elisa Kits</a></li>
                            <li><a href="/product/molicular_kits">Molicular Kits</a></li>
                          </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    </li>
                    <li className={location.pathname=='/certificates'? 'active':''}><a href="/certificates">Certifications</a></li>
                    <li className={location.pathname=='/gallery'? 'active':''}><a href="/gallery">Gallery</a></li>
                    <li className={location.pathname=='/blog'? 'active':''}><a href="/blog">Blog</a></li>
                    <li className={location.pathname=='/contact-us'? 'active':''}><a href="/contact-us">Contact Us</a></li>
                  </ul>
                </nav>
              </div>
              {/* Action Buttons */}
              <div className="header-right">
                <ul className="header-action">
                  <li className="header-btn">
                    <a href="#" className="edu-btn btn-medium">Get a Quote <i className="icon-4"></i></a>
                  </li>
                  <li className="mobile-menu-bar d-block d-xl-none">
                    <button className="hamberger-button">
                      <i className="icon-54"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <a href="/contact-us" className="edu-btn btn-medium bookmobile">Get a Quote <i className="icon-4"></i></a>
          </div>
        </div>
        {/* Mobile Menu Popup */}
        <div className="popup-mobile-menu">
          <div className="inner">
            <div className="header-top">
              <div className="logo">
                <a href="/">
                  <img className="logo-light" src="/images/logo.png" alt="Corporate Logo" />
                  {/* <img className="logo-light" src={siteSettings?.logo || 'default-logo.png'} alt="Corporate Logo" />
            <img className="logo-dark" src={siteSettings?.white_logo || 'default-logo-dark.png'} alt="Corporate Logo" /> */}
                </a>
              </div>
              <div className="close-menu">
                <button className="close-button">
                  <i className="icon-73"></i>
                </button>
              </div>
            </div>
            <ul className="mainmenu">
              <li className={location.pathname=='/'? 'active':''}><a href="/">Home</a></li>
              <li className={location.pathname=='/about-us'? 'active':''}><a href="/about-us">About Us</a></li>
              <li className={location.pathname=='/product-list'? 'active':'' + 'has-droupdown'}>
                <a href="/product-list">Product</a>
                <ul className="submenu">
                  <li><a href="/product-list/ivd-kits">IVD KITS</a></li>
                  <li><a href="/product-list/research-kits">RESEARCH KITS</a></li>
                </ul>
              </li>
              <li className={location.pathname=='/certificates'? 'active':''}><a href="/certificates">Certifications</a></li>
              <li className={location.pathname=='/gallery'? 'active':''}><a href="/gallery">Gallery</a></li>
              <li className={location.pathname=='/blog'? 'active':''}><a href="/blog">Blog</a></li>
              <li className={location.pathname=='/contact-us'? 'active':''}><a href="/contact-us">Contact Us</a></li>
            </ul>
          </div>
        </div>
      </header>
  );
};

export default Header;
